import {URL_WEB_SERVICE_LOCAL_STORAGE} from './general'
//Categories
export const GET_CATEGORIES = `${URL_WEB_SERVICE_LOCAL_STORAGE}getCategorias`;

//
export const GET_CHANNELS = `${URL_WEB_SERVICE_LOCAL_STORAGE}getCanales`;

export const GET_COVERAGE_TYPES = `${URL_WEB_SERVICE_LOCAL_STORAGE}getCoverageTypes`;

export const GET_PAQUETES = `${URL_WEB_SERVICE_LOCAL_STORAGE}getPaquetes`;

export const GET_CPLANES = `${URL_WEB_SERVICE_LOCAL_STORAGE}getCPlanes`;
