import React, { useEffect, useState } from 'react';
import {URL_WEB_SERVICE_LOCAL_STORAGE} from './constants/general'

import { CategoriesService, ChannelsService} from './actions/HttpCalls'
import { HttpClient } from './actions/HttpClient'

import './Sass/main.sass'
// import C08PeopleOrg from './Components/Organisms/Personas/C08'
import C08V3PeopleOrg from './Components/Organisms/Personas/C08/v3'

const httpClient = new HttpClient();
const categoriesService = new CategoriesService(httpClient);
const channelsService = new ChannelsService(httpClient);

function App() {

  const [dataCategoriasBk, setDataCategoriasBk] = useState([])
  const [dataChannelsBk, setDataChannelsBk] = useState([])

  const getValuesDefault = async () => {
    if( URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1 ){
      const channels = await channelsService.getChannelsItems()
      setDataChannelsBk(channels)
      
      const categories = await categoriesService.getCategoriesItems()
      setDataCategoriasBk(categories)

      console.log("channels", channels)
      console.log("categories", categories)
      // console.log("cplanes", cplanes)
    }
	}

  useEffect(() => {
		getValuesDefault();
		// eslint-disable-next-line
	}, [])

  return (
    <div className="App">
      {
        URL_WEB_SERVICE_LOCAL_STORAGE.indexOf("MS_FE_GUIA_CANALES") > -1  ?
          <>
            {
                dataChannelsBk.length > 0 ?
                <C08V3PeopleOrg
                    dataChannelsBk={dataChannelsBk}
                    dataCategoriasBk={dataCategoriasBk}
                />
                :null
            }
          </>
          :
          <C08V3PeopleOrg
              dataChannelsBk={dataChannelsBk}
              dataCategoriasBk={dataCategoriasBk}
          />

        }
    </div>
  );
}

export default App;
