import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import PropTypes from 'prop-types'

function MSolicitarPartModal(props) {
    const {
        title,
        name,
        fi_PLAN,
        fc_DESCRIPCION,
        lstPlanContratacionxPlan,
        onClickCloseRequest,
        fc_FAMILIA,
    } = props
    const [callCond, setCallCond] = useState(false)
    const [otrasOpciones, setOtrasOpciones] = useState(true)
    const [alertCond, setAlertCond] = useState(false)
    
    //const [phoneCode, setPhoneCode] = useState("+569")
    //const [localTelefono_tipo, setLocalTelefono_tipo] = useState("")
    
    const [maxlengthPhone] = useState(10)
    const [statusClass, setStatusClass] = useState('')
    const [localTelefono, setLocalTelefono] = useState('')
    const [idSolicitud, setIdSolicitud] = useState('')
    const [nombreUsuario, setNombreUsuario] = useState('')
    const [errorField, setErrorField] = useState('')
    const [errorFieldNombre, setErrorFieldNombre] = useState('')
    const [notification, setNotification] = useState('')
    const [inputDisabled, setInputDisabled] = useState(false)
    const [spanSending, setSpanSending] = useState(false)
    const fcSegmento = localStorage.getItem('referencia_producto') ? localStorage.getItem('referencia_producto') : fc_FAMILIA;


    function clearClassStatusPlanComparador() {
        setStatusClass('')
        setErrorField('')
        setErrorFieldNombre('')
        setNotification('')
        setAlertCond(false)
        setInputDisabled(false)
    }
    /*function phoneTypeComparador(e) {
        let ItemphoneArea = e.target.value
        let phoneAreaCode = ItemphoneArea === "fijo" ? "+56" : "+569";
        setPhoneCode(phoneAreaCode)
        let maxlengthInput = ItemphoneArea === "fijo" ? 10 : 10;
        setMaxlengthPhone(maxlengthInput)
        let localTelefonotipo = ItemphoneArea === "fijo" ? "fijo" : "movil";
        setLocalTelefono_tipo(localTelefonotipo)
        clearClassStatusPlanComparador()
        setLocalTelefono('')
    }*/

    function validateOnlyNumbersComparador(e) {
        clearClassStatusPlanComparador()
        let regex = /^[0-9\b]+$/;
        let inputVal = e.target.value
        if (inputVal === '' || regex.test(inputVal)) {
            // console.log("numberssssssssssssssssssssss::::::" + inputVal)
            setLocalTelefono(inputVal)
        }
    }

    function validateNotNumbersComparador(e) {
        clearClassStatusPlanComparador()
        let regex = /^[a-zA-Z ]+$/;
        let inputVal = e.target.value
        if (inputVal === '' || regex.test(inputVal)) {
            //console.log("numberssssssssssssssssssssss::::::" + inputVal)
            setNombreUsuario(inputVal)
        }
    }

    function errorSetDataStylePlanComparador(maxim = null, errorServer = null) {
        console.log("errorSetDataStylePlanComparador: ")
        let defaultError = "Ha ocurrido un error, vuelva a intentarlo"
        setSpanSending(false)
        if (maxim) {
            setErrorField("El valor debe ser de " + maxim + " caracteres.")
            setStatusClass('error')
        }
        else {
            
            // if (errorServer != null) {
            if (errorServer === "Error en el servicio.") {
                defaultError = errorServer
            }
            
            setNotification(defaultError)
            console.log("errornotifivcation: " , notification.toUpperCase().includes('error'),  notification.toUpperCase())
            console.log("errornotifivcationdefault: " , defaultError.toUpperCase().includes('error'),  defaultError.toUpperCase())
            // $(DivmSolicitar).find("[id=message]").text(defaultError);
        }
    }

    function magazineSubmitPlanComparador(c, urlsendData = null, PlanContratacionCAL) {
        clearClassStatusPlanComparador()
        console.log("magazineSubmitPlanComparador.......................", PlanContratacionCAL)

        if (!nombreUsuario) {
            setErrorFieldNombre("Ingresa el campo nombre.")
            // setAlertCond(true)
        } else if(localTelefono.length < maxlengthPhone) {
            errorSetDataStylePlanComparador(maxlengthPhone)
        } else {
            console.log("sending...");
            setSpanSending(true)
            sendDataPlan(urlsendData, PlanContratacionCAL);
            setInputDisabled(true)
            
            //setCallCond(false)
        }
    }

    function successSetDataStyleComparador() {
        setStatusClass('success')
        setNotification("Tus datos fueron inscritos correctamente.<br><strong>Pronto nos comunicaremos contigo.</strong>")
    }

    const searchCharacteristics = (search, lstPlanContratacionxPlan) => {

        const data = lstPlanContratacionxPlan.length ? lstPlanContratacionxPlan : [];
        const newCaractData = data.filter(function (PlanContra) {
            let lstPlan = []

            lstPlan = PlanContra.fc_OPCION_LBX
            // console.log("to search---------- TypeFlag:", lstPlan, search)
            return lstPlan === search
        })
        // console.log("to search---------- TypeFlag:", TypeFlag, search, newCaractData, newCaractData.length)
        return newCaractData

    }


    useEffect(() => {
        if(lstPlanContratacionxPlan && lstPlanContratacionxPlan.length===1) {
            if(lstPlanContratacionxPlan[0].fc_OPCION_LBX === 'CAL') {
                setCallCond(true);
                setOtrasOpciones(false);
            } 
        }        
    })
    let PlanContratacionCAL = searchCharacteristics('CAL', lstPlanContratacionxPlan)
    let PlanContratacionCHV = searchCharacteristics('CHV', lstPlanContratacionxPlan)
    let PlanContratacionCLN = searchCharacteristics('CLN', lstPlanContratacionxPlan)

    // console.log("PlanContratacion FC_OPCION_LBXto search----------", PlanContratacionCAL, PlanContratacionCHV, PlanContratacionCLN)

    async function sendDataPlan(urlsendData = null, PlanContratacionCAL) {

        let urlAjaxsendData = "sendDataPersonas"
        if (urlsendData != null) {
            urlAjaxsendData = urlsendData;
        }

        
        var SendDataPersonasRequest = {
            fc_nombre:nombreUsuario,
            fi_localidad:1,
            fc_telefono:localTelefono,
            fi_plan:fi_PLAN,
            fc_segmento:PlanContratacionCAL.fc_SEGMENTO
            };

        var jsonSendData = JSON.stringify(SendDataPersonasRequest);
        console.log("Entramos en sendDataPersonas", urlAjaxsendData);
        console.log(jsonSendData);
        let url_envio_formulario = localStorage.getItem('url_envio_formulario')
        const configMagazineService = {
            method: 'POST',
            url: url_envio_formulario + "registraSolicitud",
            data: SendDataPersonasRequest,
            headers: {
                'Accept': "application/json",
                'Content-Type': "application/json"
            },
        };
        try {
            console.log(configMagazineService);
            let response = await axios(configMagazineService)
            console.log("response- configMagazineService------------------------->", response.data);
            
            if(response.data && response.data !== '0'){
                setAlertCond(true)
                setIdSolicitud(response.data)
            // clearClassStatusPlanComparador()
            successSetDataStyleComparador()
            console.log("response- configMagazineService-------------------------> succc");
            } else{
                errorSetDataStylePlanComparador(null, "Error al realizar el registro")
                console.log("sucedio error")
            }
            
        } catch (error) {
            errorSetDataStylePlanComparador(null, error)
            setStatusClass('error')
            console.log("response- configMagazineService-------------------------> Error.....", error);
        }

        // setAlertCond(true)


        // ajax({
        //     url: "/magazineService/" + urlAjaxsendData,
        //     type: "POST",
        //     data: jsonSendData,
        //     dataType: "json",
        //     contentType: "application/json; charset=utf-8",
        //     success: function (data) {
        //         console.log(data, data.localMessage);
        //         if (data.localExecutionCode == 2 || data.localExecutionCode == 3 || data.localIdTrans == 0) {
        //             if (data.localIdTrans == 0) {
        //                 data.localMessage = 'Error en el servicio.'
        //             }
        //             // $(DivmSolicitar).find("[id=message]").text(data.localMessage);
        //             errorSetDataStylePlanComparador(null, data.localMessage)
        //             // errorSetDataStylePlan
        //             // $(DivmSolicitar).find("button[name='btn-tertiary-transp']").attr("style", "display: block ")
        //             // $(DivmSolicitar).find("a[name='btn-more-options']").attr("style", "display: block ")
        //             //set css error
        //         } else {
        //             //clear css error
        //             clearClassStatusPlan(index)
        //             successSetDataStylePlan(index)
        //         }
        //         // $(DivmSolicitar).find("input[name='localRut1']").prop("disabled", false);
        //         // $(DivmSolicitar).find("[id=localTelefono]").prop("disabled", false);
        //     },
        //     error: function (xhr, ajaxOptions, thrownError) {

        //         // $(DivmSolicitar).find("input[name='localRut1']").prop("disabled", false);
        //         // $(DivmSolicitar).find("[id=localTelefono]").prop("disabled", false);
        //         // $(DivmSolicitar).find("button[name='btn-tertiary-transp']").attr("style", "display: block ")
        //         // $(DivmSolicitar).find("a[name='btn-more-options']").attr("style", "display: block ")

        //         errorSetDataStylePlanComparador(null, xhr.status)
        //         console.log(thrownError);
        //         console.log(xhr.status);
        //     }
        // });
    }
    const currentUrl = window.location.href;
    let HorariosNeg=false
    if(currentUrl.indexOf("/negocios/")>-1){
        HorariosNeg = true
    }

    //setSpanSending(false);
    //setCallCond(false);
    console.log("modalClaro mSolicitar", "alertCond: " + alertCond, "callCond: "+ callCond, "statusClass: "+statusClass, "spanSending: "+spanSending  )
    
    console.log("VALOR SEGMENTO" , PlanContratacionCAL)
    return (
        <div className="modalClaro mSolicitar">
                {
                    alertCond ?
                    <>
                        <div className='statusFormImg success'>
                            <span>
                                <i className='ico-check'></i>
                                
                            </span>
                        </div>
                        <div className='mSolicitarNotTitle'>
                            <p style={{textAlign:'center', paddingTop:'12px'}}> 
                            ¡Gracias por preferirnos!
                            </p>
                        </div>
                        <div className='mSolicitarNotInfo'>
          
                            <p style={{textAlign:'center', color:'#726C6C', paddingTop:'12px'}}><strong style={{fontSize:'16px'}}>Número de solicitud: {idSolicitud}</strong></p>
                            
                            <p style={{paddingTop:'12px'}}> 
                                Horarios de atención
                            </p>
                            <ul className='ul-list-style'>
                                {HorariosNeg ?
                                <>
                                    <li>Lunes-jueves 8:00 a. m. - 6:00 p. m. y viernes 8:00 a. m. - 5:00 p. m.</li>
                                </>
                                :<>
                                    <li>Servicios Hogar: lunes-sábado 9:00 a.m. - 9:00 p.m.</li>
                                    <li>Servicios Móviles: lunes-viernes 8:30 a. m. - 6:30 p. m. y sábado 9:00 a. m. - 4:30 p. m.</li>
                                </>
                                }
                            </ul>
                        </div>
                        <div className='mSolicitarNotAct' style={{textAlign:'center', paddingTop:'12px'}}>
                            <button className='btn btnPrimario' onClick={()=>onClickCloseRequest()}> Terminar </button>
                        </div>

                        
                    </>
                        : notification && notification.toUpperCase().includes('ERROR') ?
                        <>
                        <div className='statusFormImg warning'>
                            <span>
                                <i className='ico-x'></i>
                                
                            </span>
                        </div>
                        <div className='mSolicitarNotTitle'>
                            <p className='' style={{textAlign:'center'}}> 
                            Error al guardar
                            </p>
                        </div>
                        <div className='mSolicitarNotInfo'>
            
                            <p style={{textAlign:'center', paddingTop:'12px'}}><strong>{notification}</strong></p>
                            
                            
                        </div>
                        <div className='mSolicitarNotAct' style={{textAlign:'center', paddingTop:'12px'}}>
                            <a className="textLink" id="mSolOptionsBtn" href="#!" onClick={() => { setCallCond(c => !c); clearClassStatusPlanComparador(); setStatusClass('') }}>Volver a intentar</a>
                        </div>
                    </>
                        :
                        
            <div className="mSolicitarCont">
                {

                    title ?
                        <p className="mSolTitle">{title}:</p>
                        : null
                }
                <p className="mSolTitle">{name}</p>
             
                <div className="mSolBundle">
              
                    {
                        fc_DESCRIPCION.includes("Televi") || fc_DESCRIPCION.includes("televi")
                            ? <p><i className="ico-tv"></i><span>TV</span></p>
                            : null
                    }
                   
                    {
                        fc_DESCRIPCION.includes("Telef") || fc_DESCRIPCION.includes("telef")
                            ? <p><i className="ico-tv"></i><span>Telefónia</span></p>
                            : null
                    }
              
                </div>

             
                {/* <p className="mSolDesc">{fc_DESCRIPCION}</p> */}
                {
                    !callCond && !alertCond ?
                        <div className="mSolSubs" id="mSolOptions">
                            <p>Selecciona el medio de compra preferido para ti:</p>
                            {/* <p>Selecciona el medio de compra que sea m&aacute;s c&oacute;modo para ti:</p> */}
                            
                            {
                                PlanContratacionCAL.length
                                    ?
                                    <a className="btn btnPrimario withIco" id="callMeNowBtn" href="#!" onClick={() => {setCallCond(c => !c); setSpanSending(false);}}>
                                        <i className="ico-phone"></i>
                                        <span>Asesor de ventas</span>
                                    </a>
                                    
                                    : null
                               
       
                            }

                            
                           {
                              PlanContratacionCLN.length
                              ?
                              <a className="btn btnPrimario withIco" href={`${PlanContratacionCLN[0].fc_URL}`}>
                                  <i className="ico-mouse-pointer"></i>
                                  <span>Comprar en l&iacute;nea</span>
                              </a>
                              : null
                         }

                            {
                                PlanContratacionCHV.length
                                    ?
                                    <a className="btn btnPrimario withIco" href={`${PlanContratacionCHV[0].fc_URL}`}>
                                        <i className="ico-message-circle"></i>
                                        <span>Chat de Ventas</span>
                                    </a>
                                    : null
                            }
                        </div>
                        : <></>
                }
                {
             

                    callCond ?
                 
                        <div className="mSolForm">
                             
                            <p>Ingresa los siguientes datos para iniciar el contacto</p>
                            <br />
                            <form action="">
                                
                                <div style={{width: '90%',marginBottom:'15px'}}>
                                    <label  style={{fontSize:'14px'}}><strong>Nombre:</strong></label><br/>
                                    <input
                                        //className={errorFieldNombre ? 'inputPhoneError val error' : ''}
                                        type="text"
                                        onChange={validateNotNumbersComparador}
                                   
                                        placeholder="Ingresa tu nombre"
                                        value={nombreUsuario}
                                        style={{marginTop:'10px'}}
                                    />
                                    {errorFieldNombre &&
                                        <div className="c13Alerts" style={{
                                            marginTop: '10px',
                                            marginBottom: '10px'}}>
                                            <div className="notification error">
                                                <p>{errorFieldNombre}</p>
                                            </div>
                                        </div>

                                         
                                    }
                                </div>
                                <div style={{width: '90%'}}>
                                    <label style={{fontSize:'14px'}}><strong>N&uacute;mero de contacto:</strong></label>
                                    <input
                                        //className={statusClass}
                                        type="text"
                                        maxLength={maxlengthPhone}
                                        onChange={validateOnlyNumbersComparador}
                                        placeholder="Ingresa tu n&uacute;mero fijo o m&oacute;vil"
                                        value={localTelefono}
                                        disabled={inputDisabled}
                                        style={{marginTop:'10px'}}
                                    />
                                    {errorField &&
                                        <div className="c13Alerts" style={{
                                            marginTop: '10px',
                                            marginBottom: '10px'}}>
                                            <div className="notification error">
                                                <p>{errorField}</p>
                                            </div>
                                        </div>
                                    }
                                </div>
                            
                                <div className="mSolFormActions">
                               
                                    {/* <button className="btn btnPrimario" type="submit" onClick={() => { setCallCond(c => !c); setAlertCond(c => !c); magazineSubmitPlanComparador(); }}>Enviar</button> */}
                               
                                    {
                                        
                                        spanSending ?
                                            statusClass === ""
                                                ?
                                                <span className="btn btnPrimario">Enviando...</span>
                                                : null
                                            :
                                      
                                            <button className="btn btnPrimario" type="button" onClick={() => { magazineSubmitPlanComparador(c => !c, 'sendDataPersonas', PlanContratacionCAL[0]); }}>Enviar</button>
                                            
                                    }
                                    { otrasOpciones ?
                                        <a className="textLink" id="mSolOptionsBtn" href="#!" onClick={() => { setCallCond(c => !c); clearClassStatusPlanComparador(); setNombreUsuario(''); setLocalTelefono(''); setStatusClass('') }}>Ver otras opciones</a>
                                        : null
                                    }
                                    
                                </div>
                            </form>
                        </div>
                        : <></>
                }
                
            </div>
            }
        </div>
    )
}

MSolicitarPartModal.defaultProps = {}


// MSolicitarPartModal.propTypes = {}

export default MSolicitarPartModal
